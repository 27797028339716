import { jsx } from "react/jsx-runtime";
import {
  CONTROLLED_DATE_TIME_PICKER_TYPES,
  DSControlledDateTimePicker
} from "@elliemae/ds-form-date-time-picker";
import _ from "lodash";
import { useFormState } from "react-hook-form";
import { DSFormLayoutBlockItem } from "@elliemae/ds-form-layout-blocks";
import { Grid } from "@elliemae/ds-grid";
import { useState } from "react";
import { useFieldVisibilityAndDisability } from "./utils/fieldVisibilityAndDisability";
import { getDefaultFocusedDate } from "./utils/utils";
const Date = ({
  renderedField,
  schemaForm,
  rules,
  name,
  label,
  required,
  pathValue,
  feedbackMessage,
  ui,
  visible,
  disabled,
  disableCheck,
  visibleCheck,
  preview
}) => {
  const [localDateTimeValue, setLocalDateTimeValue] = useState("");
  const [localDateValue, setLocalDateValue] = useState("");
  const [localTimeValue, setLocalTimeValue] = useState("");
  const { errors } = useFormState({ control: schemaForm.control });
  const errorMessage = _.get(errors, pathValue)?.message;
  const { isVisible, isDisabled } = useFieldVisibilityAndDisability({
    schemaForm,
    rules,
    visible,
    disabled,
    visibleCheck,
    disableCheck,
    preview
  });
  const handleOnDateChange = (newDate) => {
    const strDateTime = `${newDate} ${localTimeValue}`;
    setLocalDateValue(strDateTime);
  };
  const handleOnDateOnlyChange = (newDate) => {
    const strDateTime = `${newDate}`;
    setLocalDateValue(strDateTime);
  };
  const handleOnTimeChange = (newTime) => {
    const strDateTime = `${localDateValue} ${newTime}`;
    setLocalTimeValue(strDateTime);
  };
  const handleOnDateTimeChange = (newDateTime) => {
    const [prevDate] = localDateTimeValue.split(" ");
    const [newDate, newTime] = newDateTime.split(" ");
    const isDefaultTime = newDate && prevDate !== newDate && !newDate.includes("_") && !newTime.includes("12:00 AM");
    const actualDateTime = isDefaultTime ? `${newDate} 12:00 AM` : newDateTime;
    setLocalDateTimeValue(actualDateTime);
  };
  const isFullDateTime = ui?.type === CONTROLLED_DATE_TIME_PICKER_TYPES.DATE_TIME.FULL;
  const dateTimeProps = isFullDateTime ? {
    dateTime: localDateTimeValue,
    onDateChange: (newDate) => {
      handleOnDateChange(newDate);
      renderedField.onChange(localDateTimeValue);
    },
    onTimeChange: (newTime) => {
      handleOnTimeChange(newTime);
      renderedField.onChange(localDateTimeValue);
    },
    onDateTimeChange: (newDateTime) => {
      handleOnDateTimeChange(newDateTime);
      renderedField.onChange(localDateTimeValue);
    }
  } : {
    date: localDateValue,
    onDateChange: (newDate) => {
      handleOnDateOnlyChange(newDate);
      renderedField.onChange(localDateValue);
    }
  };
  return /* @__PURE__ */ jsx(Grid, { span: ui.col_size, style: { display: isVisible ? "block" : "none" }, children: /* @__PURE__ */ jsx(
    DSFormLayoutBlockItem,
    {
      inputID: `epps-field-id-${name}`,
      label,
      required,
      feedbackMessage,
      hasError: !!errorMessage,
      validationMessage: errorMessage,
      children: /* @__PURE__ */ jsx(
        DSControlledDateTimePicker,
        {
          ...renderedField,
          ...dateTimeProps,
          onCalendarOpenFocusedDay: getDefaultFocusedDate(),
          disabled: isDisabled,
          id: pathValue,
          type: ui?.type,
          style: { width: "fit-content" }
        }
      )
    }
  ) });
};
export {
  Date
};
