import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Session, {
  ALL_SUMMARY_PARAMS,
  IS_VALIDATE,
} from '../../common/services/Session';
import { buysideRequested } from '../../common/utils/constants/ErrorCodes';
import { stopLog } from '../../common/utils/logger';
import { isEmpty } from '../../common/utils/shared';
import {
  getBuysideDataAction,
  getRateSelectorAction,
  getSettingAction,
  getValidateDataAction,
} from '../../data/lockSummary/actionCreators';
import { closeLoader, openLoader } from '../../data/screenLoader/actions';
import { AppDispatch } from '../../store';
import {
  StyledEppsContainerBody,
  StyledEppsParentView,
  StyledEppsWrapper,
} from '../global/styles/styled';
import { CompareRates } from './components/CompareRates';
import { FooterActionButtons } from './components/FooterActionButtons';
import { LockConfirm } from './components/LockConfirm';

export const LockConfirmWrapper = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [isCompareRatesView, setIsCompareRatesView] = useState(false);
  const isValidate = Session.get(IS_VALIDATE);
  const { current: userWentToSearchResult } = useRef(
    !isEmpty(Session.get(ALL_SUMMARY_PARAMS)),
  );
  const navigate = useNavigate();
  const initData = async () => {
    if (!userWentToSearchResult) {
      await dispatch(getSettingAction());
      if (isValidate) {
        await dispatch(getValidateDataAction(navigate));
      }
      if (!isValidate) {
        await dispatch(getBuysideDataAction(navigate));
      }
    }
    if (userWentToSearchResult) {
      dispatch(openLoader('Loading'));
      await dispatch(getRateSelectorAction());
      dispatch(closeLoader());
    }
  };

  useEffect(() => {
    initData();
    stopLog(buysideRequested);
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {isCompareRatesView && (
        <CompareRates handleBack={() => setIsCompareRatesView(false)} />
      )}
      {!isCompareRatesView && (
        <StyledEppsParentView>
          <StyledEppsContainerBody>
            <StyledEppsWrapper>
              <LockConfirm setIsCompareRatesView={setIsCompareRatesView} />
            </StyledEppsWrapper>
          </StyledEppsContainerBody>
          <FooterActionButtons />
        </StyledEppsParentView>
      )}
    </>
  );
};
