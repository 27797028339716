import { jsx, jsxs } from "react/jsx-runtime";
import { Grid } from "@elliemae/ds-grid";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  evaluateRuleById,
  isShallowEqual,
  zeroPadCensusTract
} from "./utils/utils";
const PropertyReadOnly = ({
  schemaForm,
  rules,
  preview,
  visible,
  visibleCheck
}) => {
  const [isVisible, setIsVisible] = useState(visible);
  const msa = schemaForm.getValues("property.msa");
  const countyCode = schemaForm.getValues("property.countyCode");
  const stateCode = schemaForm.getValues("property.stateCode");
  const censusTract = schemaForm.getValues("property.censusTract");
  const selectorData = preview ? () => {
  } : ({ eppsData: { lookups = {} } }) => lookups?.data?.geocodes;
  const getGeocodes = useSelector(selectorData, isShallowEqual) || {};
  useEffect(() => {
    setIsVisible(visible);
  }, [visible]);
  useEffect(() => {
    const updateVisibility = () => {
      if (visibleCheck?.handler) {
        setIsVisible(
          evaluateRuleById(
            visibleCheck.handler,
            schemaForm.getValues(),
            rules,
            preview
          ) ? !visible : visible
        );
      }
    };
    const subscription = schemaForm.watch(() => {
      updateVisibility();
    });
    updateVisibility();
    return () => subscription.unsubscribe();
  }, [schemaForm]);
  return /* @__PURE__ */ jsxs(
    Grid,
    {
      span: 12,
      gutter: "xxs",
      style: { display: "flex", flexWrap: "nowrap" },
      children: [
        /* @__PURE__ */ jsxs(Grid, { style: { width: "25%" }, children: [
          /* @__PURE__ */ jsx("span", { style: { display: "block", marginBottom: "10px" }, children: "MSA" }),
          /* @__PURE__ */ jsx("b", { children: !isEmpty(getGeocodes) ? getGeocodes.msa : msa })
        ] }),
        /* @__PURE__ */ jsxs(Grid, { style: { width: "25%" }, children: [
          /* @__PURE__ */ jsx("span", { style: { display: "block", marginBottom: "10px" }, children: "Census Tract" }),
          /* @__PURE__ */ jsx("b", { children: !isEmpty(getGeocodes) ? zeroPadCensusTract(
            getGeocodes.censusTract ? String(getGeocodes.censusTract) : ""
          ) : zeroPadCensusTract(censusTract) })
        ] }),
        /* @__PURE__ */ jsxs(Grid, { style: { width: "25%" }, children: [
          /* @__PURE__ */ jsx("span", { style: { display: "block", marginBottom: "10px" }, children: "County Code" }),
          /* @__PURE__ */ jsx("b", { children: !isEmpty(getGeocodes) ? getGeocodes.countyCode : countyCode })
        ] }),
        /* @__PURE__ */ jsxs(Grid, { style: { width: "25%" }, children: [
          /* @__PURE__ */ jsx("span", { style: { display: "block", marginBottom: "10px" }, children: "State Code" }),
          /* @__PURE__ */ jsx("b", { children: !isEmpty(getGeocodes) ? getGeocodes.stateCode : stateCode })
        ] })
      ]
    }
  );
};
export {
  PropertyReadOnly
};
