import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PATH_SEARCH_RESULTS } from '../../../../route/constants';
import { searchFormActions } from '../../../../store/searchForm';
import { FooterButtons } from '../../../components/FooterButtons';
import { setLoanInfo } from '../../../components/Form/utils/common';

export const FooterForm = ({ schemaForm }: { schemaForm: any }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSearchAction = schemaForm.handleSubmit(
    async (formData: any) => {
      dispatch(setLoanInfo(formData) as any);
      dispatch(searchFormActions.setBannerIsOpen(false));
      navigate(PATH_SEARCH_RESULTS);
    },
    (errors: any) => {
      dispatch(searchFormActions.setBannerIsOpen(true));
    },
  );

  return (
    <FooterButtons
      buttons={[
        {
          type: 'primary',
          text: 'Search',
          onClick: handleSearchAction,
          'data-testid': 'footer-button-search',
        },
      ]}
    />
  );
};
