import { DSBreadcrumb } from '@elliemae/ds-breadcrumb';
import { DSPageHeaderV2 } from '@elliemae/ds-page-header';
import { getAppConfigValue } from '@elliemae/pui-app-sdk';
import { useLoanDataFieldSelectorHook } from '@frontend/pricing-search';
import { string } from 'prop-types';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Rights from '../../../../common/services/Rights';
import Session, {
  ALL_SUMMARY_PARAMS,
  IS_BUYSIDE,
  IS_LOCK_EXTENSION,
  IS_SELLSIDE,
  IS_VALIDATE,
  ORIGIN_SOURCE,
  ORIGIN_TRANSACTION_TYPE,
} from '../../../../common/services/Session';
import { isEmpty } from '../../../../common/utils/shared';
import {
  useLockedProgramSelector,
  useOriginalLoanInformationSelector,
} from '../../../../data/useSelectors';
import { searchFormActions } from '../../../../store/searchForm';
import { setLoanInfo } from '../../Form/utils/common';
import { useSearchFormSelector } from '../../Form/utils/customHooks';
import { HeaderModal } from './HeaderModal';
import { HeaderTitle } from './HeaderTitle';
import { HeaderToolbar } from './HeaderTooltip';
import {
  LO_PAGE_TITLES,
  LOCK_EXTENSION_TITLES,
  SEC_BUYSIDE_PAGE_TITLES,
  SEC_SELLSIDE_PAGE_TITLES,
} from './utils/constants';
import { checkIsLockRevise, getMenuOptions } from './utils/helpers';

export const HeaderMenu = ({ loanId, applicationName, schemaForm }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const tabId = location.pathname;
  const errorForm = (useSearchFormSelector('errorForm') as any).value;
  const [isOpen, setOpen] = useState(false);
  const isSmartClient = applicationName === 'ENC';
  const isSecondary = Session.get(ORIGIN_SOURCE) === 'secondarylock';
  const isValidate = Session.get(IS_VALIDATE);
  const { status } = useLockedProgramSelector();
  const loanData = useLoanDataFieldSelectorHook() as any;
  const { requestAction, requestLockStatus, requestLockType, lockId } =
    loanData;
  const disableRevised =
    requestAction === 4 && requestLockStatus === 2 && requestLockType === 1;
  const disablePricingPage =
    status !== 'eligible' && isEmpty(Session.get(ALL_SUMMARY_PARAMS));
  const lockRequests = useOriginalLoanInformationSelector('lockRequests');
  let showSearchLink = true;
  if (disableRevised) {
    showSearchLink = checkIsLockRevise(lockId, lockRequests);
  }

  const tabIds = getMenuOptions(
    tabId,
    isSecondary,
    disablePricingPage,
    disableRevised,
  ) as any[];
  const searchFormId = isSecondary
    ? tabIds?.[1]?.items?.[0]?.id
    : tabIds?.[0]?.items?.[0]?.id;
  const searchResultsId = isSecondary
    ? tabIds?.[1]?.items?.[1]?.id
    : tabIds?.[0]?.items?.[1]?.id;
  const lockSummaryId = isSecondary
    ? tabIds?.[1]?.items?.[2]?.id
    : tabIds?.[0]?.items?.[2]?.id;
  const isBuySide = Session.get(IS_BUYSIDE);
  const isSellSide = Session.get(IS_SELLSIDE);
  const isValidateBuyside = isBuySide && isValidate;
  let PAGE_TITLES;
  if (isBuySide) {
    PAGE_TITLES = SEC_BUYSIDE_PAGE_TITLES;
  } else if (isSellSide) {
    PAGE_TITLES = SEC_SELLSIDE_PAGE_TITLES;
  } else {
    PAGE_TITLES = LO_PAGE_TITLES;
  }

  const updateStore = useCallback(() => {
    return new Promise<boolean>((resolve) => {
      schemaForm.handleSubmit(
        (data) => {
          dispatch(setLoanInfo(data) as any);
          dispatch(searchFormActions.setBannerIsOpen(false));
          resolve(true);
        },
        (errors) => {
          dispatch(searchFormActions.setBannerIsOpen(true));
          resolve(false);
        },
      )();
    });
  }, [schemaForm]);

  const isLockExtension = Boolean(Session.get(IS_LOCK_EXTENSION));

  const handleOpenModal = () => setOpen((prev) => !prev);

  const handleViewSelected = (newTabID, forceViewChange?: boolean) => {
    if (newTabID !== tabId || forceViewChange) {
      // TODO maybe this validation should be inside an action
      if (newTabID === '/search-form') {
        // Validation Search Form
        const { errorFields, bannerIsOpen } = errorForm;
        if (errorFields.length > 0 && !bannerIsOpen) {
          dispatch(searchFormActions.setBannerIsOpen(true));
        } else if (errorFields.length === 0) {
          navigate(newTabID);
          dispatch(searchFormActions.setBannerIsOpen(false));
        }
      } else {
        if (newTabID === '/search-results') {
          updateStore().then((isValid) => {
            if (isValid) {
              navigate(newTabID);
            }
          });
          return;
        }
        navigate(newTabID);
      }
    }
  };

  const dropdownBtn = document.querySelector<HTMLButtonElement>(
    '[data-testid="page-header-dropdown-trigger-component"]',
  );

  return (
    <>
      <HeaderModal {...{ isOpen, handleOpenModal }} />
      <DSPageHeaderV2
        pageTitle={
          <HeaderTitle
            {...{
              title: (
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    dropdownBtn?.focus();
                    dropdownBtn?.click();
                  }}
                >
                  {isLockExtension && (
                    <DSBreadcrumb trailingSlash={false}>
                      <DSBreadcrumb.Item
                        label={LOCK_EXTENSION_TITLES['/lock-extension']}
                        active
                      />
                    </DSBreadcrumb>
                  )}
                  {!isLockExtension && (
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    <>
                      {!isSecondary &&
                      (tabId === searchFormId || tabId === searchResultsId) ? (
                        <DSBreadcrumb trailingSlash={false}>
                          <DSBreadcrumb.Item
                            label={PAGE_TITLES[searchFormId]}
                            active={tabId === searchFormId}
                            onClick={() => {
                              handleViewSelected(searchFormId);
                            }}
                          />
                          <DSBreadcrumb.Item
                            label={PAGE_TITLES[searchResultsId]}
                            active={tabId === searchResultsId}
                            onClick={() => {
                              handleViewSelected(searchResultsId);
                            }}
                          />
                        </DSBreadcrumb>
                      ) : (
                        <DSBreadcrumb trailingSlash={false}>
                          {showSearchLink ? (
                            <DSBreadcrumb.Item
                              label={PAGE_TITLES[searchFormId]}
                              active={
                                tabId === searchFormId ||
                                (isValidate && !Rights.accessBuyside)
                              }
                              onClick={() => {
                                if (
                                  !isValidate ||
                                  (isValidate && Rights.accessBuyside)
                                ) {
                                  handleViewSelected(searchFormId);
                                }
                              }}
                            />
                          ) : (
                            // eslint-disable-next-line react/jsx-no-useless-fragment
                            <></>
                          )}
                          <DSBreadcrumb.Item
                            label={PAGE_TITLES[searchResultsId]}
                            active={
                              tabId === searchResultsId ||
                              (isValidate && !Rights.accessBuyside)
                            }
                            onClick={() => {
                              if (
                                !isValidate ||
                                (isValidate && Rights.accessBuyside)
                              ) {
                                handleViewSelected(searchResultsId);
                              }
                            }}
                          />
                          {!isSecondary ? (
                            <DSBreadcrumb.Item
                              label={PAGE_TITLES[lockSummaryId]}
                              active={tabId === lockSummaryId}
                              onClick={() => {
                                handleViewSelected(lockSummaryId);
                              }}
                            />
                          ) : (
                            <>
                              {Rights.accessBuyside || isValidate ? (
                                <DSBreadcrumb.Item
                                  label={SEC_BUYSIDE_PAGE_TITLES[lockSummaryId]}
                                  active={
                                    (tabId === lockSummaryId && isBuySide) ||
                                    !Rights.accessBuyside
                                  }
                                  onClick={() => {
                                    if (Rights.accessBuySide) {
                                      if (isSellSide) {
                                        Session.set(
                                          ORIGIN_TRANSACTION_TYPE,
                                          getAppConfigValue<string>(
                                            'partner-ui.dataSourceBuyside',
                                          ),
                                        );
                                        handleViewSelected(lockSummaryId, true);
                                      } else {
                                        handleViewSelected(lockSummaryId);
                                      }
                                    }
                                  }}
                                />
                              ) : (
                                // eslint-disable-next-line react/jsx-no-useless-fragment
                                <></>
                              )}
                              {Rights.accessSellside && !isValidateBuyside ? (
                                <DSBreadcrumb.Item
                                  label={
                                    SEC_SELLSIDE_PAGE_TITLES[lockSummaryId]
                                  }
                                  active={
                                    (tabId === lockSummaryId && isSellSide) ||
                                    !Rights.accessSellside
                                  }
                                  onClick={() => {
                                    if (Rights.accessSellside) {
                                      if (isBuySide) {
                                        Session.set(
                                          ORIGIN_TRANSACTION_TYPE,
                                          getAppConfigValue<string>(
                                            'partner-ui.dataSourceSellside',
                                          ),
                                        );
                                        handleViewSelected(lockSummaryId, true);
                                      } else {
                                        handleViewSelected(lockSummaryId);
                                      }
                                    }
                                  }}
                                />
                              ) : (
                                // eslint-disable-next-line react/jsx-no-useless-fragment
                                <></>
                              )}
                            </>
                          )}
                        </DSBreadcrumb>
                      )}
                    </>
                  )}
                </span>
              ),
              isSmartClient,
            }}
          />
        }
        toolbar={
          <HeaderToolbar {...{ isSmartClient, handleOpenModal, loanId }} />
        }
      />
    </>
  );
};

HeaderMenu.defaultProps = {
  loanId: '',
  applicationName: '',
};

HeaderMenu.propTypes = {
  loanId: string,
  applicationName: string,
};
