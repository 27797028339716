import api from '../utils/api';
import Common from './Common';
import Session, { IS_LO, JWT_TOKEN } from './Session';

export const getGeocodes = async (loanId) => {
  let result;
  if (Session.get(IS_LO)) {
    const patToken = await Common.getPATToken();
    const originId = await Common.getOriginId();
    result = await api.getGeocodesV1({ patToken, originId }, loanId);
  } else {
    const jwtToken = await Session.get(JWT_TOKEN);
    result = await api.getGeocodesV2({ jwtToken }, loanId);
  }
  return result;
};

export const getLoanLimits = async (loanData) => {

  try {
    let result;
    if (Session.get(IS_LO)) {
      const patToken = await Common.getPATToken();
      const originId = await Common.getOriginId();
      result = await api.getLoanLimitsV1({ patToken, originId }, loanData);
    } else {
      const jwtToken = await Session.get(JWT_TOKEN);
      result = await api.getLoanLimitsV2({ jwtToken }, loanData);
    }

    return result;
  } catch (e) {
    console.error(e);
  }
  return [];
};

export const getZipLocations = async (zip) => {
  let locations = [];
  if (Session.get(IS_LO)) {
    locations = await api.getWebhookZipLocations(zip);
  } else {
    locations = await api.getZipLocations(zip, {
      customToken: { value: Session.get(JWT_TOKEN) },
      tokenType: 'Bearer',
    });
  }
  return locations;
};

export const getStateCounties = async (stateID) => {
  let counties = [];
  if (Session.get(IS_LO)) {
    counties = await api.getWebhookStateCounties(stateID);
  } else {
    counties = await api.getStateCounties(stateID, {
      customToken: { value: Session.get(JWT_TOKEN) },
      tokenType: 'Bearer',
    });
  }
  return counties;
};

export const getAllLookups = async () => {
  let response: Response | any[] = [];
  if (Session.get(IS_LO)) {
    response = await api.getWebhookLookups('all');
  } else {
    response = await api.getAllLookups({
      customToken: { value: Session.get(JWT_TOKEN) },
      tokenType: 'Bearer',
    });
  }
  // TODO(ngen): this was using response.code, but fetch statements use response.status
  // This error flow never would have triggered, and would have resulted in a silent error with empty lookup options on screen
  if ((response as Response)?.status) {
    throw new Error((response as Response)?.status.toString());
  }
  return response;
};
