import { jsx } from "react/jsx-runtime";
import { DSInputText } from "@elliemae/ds-form-input-text";
import { DSFormLayoutBlockItem } from "@elliemae/ds-form-layout-blocks";
import { Grid } from "@elliemae/ds-grid";
import { useFormState } from "react-hook-form";
import { useFieldVisibilityAndDisability } from "./utils/fieldVisibilityAndDisability";
const Readonly = ({
  renderedField,
  schemaForm,
  rules,
  id,
  name,
  label,
  required,
  pathValue,
  feedbackMessage,
  type,
  ui,
  visible,
  disabled,
  disableCheck,
  visibleCheck,
  preview
}) => {
  const { isVisible, isDisabled } = useFieldVisibilityAndDisability({
    schemaForm,
    rules,
    visible,
    disabled,
    visibleCheck,
    disableCheck,
    preview
  });
  const { errors } = useFormState({ control: schemaForm.control });
  return /* @__PURE__ */ jsx(Grid, { span: ui.col_size, style: { display: isVisible ? "block" : "none" }, children: /* @__PURE__ */ jsx(DSFormLayoutBlockItem, { name, label, children: /* @__PURE__ */ jsx(
    DSInputText,
    {
      ...renderedField,
      readOnly: true,
      "data-testid": "readonly",
      value: renderedField?.value,
      id: pathValue
    }
  ) }) });
};
export {
  Readonly
};
