import { jsx } from "react/jsx-runtime";
import { Grid } from "@elliemae/ds-grid";
import { DSControlledCheckbox } from "@elliemae/ds-form-checkbox";
import { DSFormLayoutBlockItem } from "@elliemae/ds-form-layout-blocks";
import _ from "lodash";
import { useFormState } from "react-hook-form";
import { useCheckboxFieldUpdateLogic } from "./utils/fieldUpdateLogic";
import { useFieldVisibilityAndDisability } from "./utils/fieldVisibilityAndDisability";
const Checkbox = ({
  renderedField,
  schemaForm,
  rules,
  name,
  label,
  required,
  pathValue,
  feedbackMessage,
  ui,
  visible,
  disabled,
  disableCheck,
  visibleCheck,
  preview
}) => {
  const checkboxUpdateLogic = useCheckboxFieldUpdateLogic();
  const { errors } = useFormState({ control: schemaForm.control });
  const errorMessage = _.get(errors, pathValue)?.message;
  const { isVisible, isDisabled } = useFieldVisibilityAndDisability({
    schemaForm,
    rules,
    visible,
    disabled,
    visibleCheck,
    disableCheck,
    preview
  });
  return /* @__PURE__ */ jsx(
    Grid,
    {
      mt: "2px",
      gutter: "xs",
      height: "100%",
      wrap: "nowrap",
      alignItems: "center",
      justifyContent: "flex-start",
      span: ui.col_size ?? 2,
      cols: ["auto", "auto", "auto", "auto"],
      style: { display: isVisible ? "block" : "none" },
      children: /* @__PURE__ */ jsx(
        DSFormLayoutBlockItem,
        {
          inputID: `epps-field-id-${name}`,
          label,
          required,
          feedbackMessage,
          hasError: !!errorMessage,
          validationMessage: errorMessage,
          children: /* @__PURE__ */ jsx(
            DSControlledCheckbox,
            {
              ...renderedField,
              checked: renderedField?.value ?? false,
              name,
              id: pathValue,
              onChange: checkboxUpdateLogic(renderedField, schemaForm),
              disabled: isDisabled
            }
          )
        }
      )
    }
  );
};
export {
  Checkbox
};
