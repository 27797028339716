import { DataTable } from '@elliemae/ds-data-table';
import { ModalHeader } from '@elliemae/ds-modal-slide';
import { DSTab, DSTabs, TabTypes } from '@elliemae/ds-tabs';
import { useLoanDataFieldSelector, useLookup } from '@frontend/pricing-search';
import { get } from 'lodash';
import { arrayOf, bool, func, shape } from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setAllRatesRowData } from '../../../../common/Qualification/QualificationActions';
import Session, {
  ALL_SUMMARY_PARAMS,
} from '../../../../common/services/Session';
import { useAppData } from '../../../../common/utils/customHooks/useAppData';
import { selectProduct } from '../../../../data/lockSummary/actions';
import {
  useLoanInformationField,
  useSummaryDetailsField,
} from '../../../../data/useSelectors';
import { PATH_LOCK_SUMMARY } from '../../../../route/constants';
import { getAdjustmentDetails } from '../../../AdjustmentDetails/AdjustmentDetailsAction';
import { ModalSlide } from '../../../components/ModalSlide';
import { RowsGridEmpty } from '../../../components/RowsGridEmpty/RowsGridEmpty';
import { StyledDataTableWrapper } from '../../../global/styles/styled';
import { ProgramDetailsModal } from '../../../LockSummary/components/ProgramDetailsModal/ProgramDetailsModal';
import { GridPriceDetails } from '../GridPriceDetails/GridPriceDetails';
import { transformRateSelectorDataToSlide } from '../GridPriceDetails/helpers';
import { EligibleProductsToolbar } from './EligibleProductsToolbar';
import { filterPrograms, getEligibleGridColumns } from './helpers';
import { useEligibleProductsGrid } from './useEligibleProductsGrid';

export const EligibleProductsGrid = ({
  rows,
  activeFilters,
  onFilterChange,
  isLoanPrograms,
  refreshPrograms,
  onSearch,
  updateAdjustmentDetailsCache,
}: any = {}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const setEligibleProducts = useAppData('setEligibleProducts');
  const clientsettings = useLookup('clientSettings', '');
  const isBuySideHistorical = get(
    clientsettings,
    '[0].options.isBuySideHistorical',
  );
  const { relockRequest } = useSelector(({ EPPS }) => EPPS.loanInfo || {});
  const lienPosition = useLoanInformationField('lienPosition', 1);
  const productOptions = useLoanDataFieldSelector('productOptions', []) || [];
  const loanTerms = (useLoanDataFieldSelector('productType', []) ||
    []) as any[];
  const requestAction = useLoanDataFieldSelector('requestAction');
  const requestLockStatus = useLoanDataFieldSelector('requestLockStatus');
  const disableLockLinks = requestAction === 2 && requestLockStatus === 2;
  const lookupsLoanTerms = useLookup('loanTerms') as any[];
  let loanTermsTabs: any[] = [];
  if (Array.isArray(loanTerms) && lookupsLoanTerms) {
    loanTermsTabs = lookupsLoanTerms.filter((obj) =>
      loanTerms.includes(obj.value),
    );
    loanTermsTabs.unshift({ label: 'All' });
  }

  const { loanId } = useSelector(({ EPPS }) => EPPS.loanPrograms || {});
  const {
    lien: { armIndexValue = 0, arm = false } = {},
    loan: { mortgageType = '' } = {},
  } = useSummaryDetailsField() as any;

  const [expandedRows, setExpandedRows] = useState({ 1: true });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleSelectProduct = (
    programID,
    rateDataID,
    lockEffectiveDate,
    wcpPolicyApplied,
  ) => {
    Session.set(ALL_SUMMARY_PARAMS, {
      loanId,
      programId: programID,
      rateDataId: rateDataID,
      lockEffectiveDate,
      wcpPolicyApplied,
    });
    navigate(PATH_LOCK_SUMMARY);
  };

  const handleRefreshButton = (lock, loanData) => {
    // TODO(ngen): this was missing the dep array
    // This was also a useCallback - but I changed it to a regular callback function
    // Not sure how this was supposed to work
    const lockData = { lockDays: [Number(lock)] };
    const refreshLoanData = { ...loanData, ...lockData };
    onSearch(refreshLoanData, false);
  };

  const {
    columns,
    handleSelectRow,
    slide,
    setSlide,
    programSlide,
    setProgramSlide,
    filteredRows,
  } = useEligibleProductsGrid({
    dispatch,
    loanId,
    relockRequest,
    lienPosition,
    productOptions,
    armIndexValue,
    mortgageType,
    getEligibleGridColumns,
    transformRateSelectorDataToSlide,
    getAdjustmentDetails,
    activeFilters,
    filterPrograms,
    selectProduct,
    clientsettings,
    rows,
    refreshPrograms,
    arm,
    expandedRows,
    windowWidth,
    handleSelectProduct,
    handleRefreshButton,
    updateAdjustmentDetailsCache,
    disableLockLinks,
    isBuySideHistorical,
  } as any);

  useEffect(() => {
    // TODO(ngen): this set command is causing an infinite loop
    // setEligibleProducts(filteredRows.length);
  }, [filteredRows]);

  useEffect(() => {
    setExpandedRows({ 1: true });
  }, [refreshPrograms]);

  // Handle window resize for program row width calculation
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (clientsettings !== '') {
    return (
      <>
        <div style={{ paddingTop: 10, paddingBottom: 10 }}>
          <DSTabs type={TabTypes.SUBTABS}>
            {loanTermsTabs.map((tab, idx) => (
              <DSTab
                key={tab.label.concat(idx)}
                tabId={`tab${idx + 1}`}
                title={tab.label}
                onClick={() => {
                  onFilterChange('term', tab);
                }}
              />
            ))}
          </DSTabs>
        </div>
        <div
          style={{
            height: 'calc(100% - 60px)',
            flexDirection: 'column',
            display: 'flex',
          }}
        >
          <EligibleProductsToolbar
            activeFilters={activeFilters}
            onFilterChange={onFilterChange}
            refreshPrograms={refreshPrograms}
            showTarget
            showFlagged
          />
          <ModalSlide
            header={
              <ModalHeader
                title="Pricing Details"
                onClose={() =>
                  setSlide({
                    open: false,
                    data: null,
                    rowData: null,
                    rateData: null,
                  })
                }
              />
            }
            isOpen={slide.open}
          >
            {slide.data && (
              <div style={{ padding: 16 }}>
                <GridPriceDetails isSlide data={slide.data} />
              </div>
            )}
          </ModalSlide>
          <ProgramDetailsModal
            isSlide
            isOpen={programSlide}
            toggleModalSlide={() => setProgramSlide(false)}
          />
          <StyledDataTableWrapper>
            <DataTable
              paginated
              sortable
              searchFilters
              isExpandable
              uniqueRowAccessor="id"
              expandedRows={expandedRows}
              onRowExpand={(newExpandedRows) => {
                setExpandedRows(newExpandedRows);
              }}
              colsLayoutStyle="auto"
              columns={columns}
              data={filteredRows}
              hiddenColumns={!productOptions.length ? ['options'] : []}
              rowKey="programID"
              cellRendererProps={{
                onClickProgramName: (rowData) => {
                  dispatch(selectProduct(rowData));
                  setProgramSlide(true);
                },
                onClickPricing: (rowData) => {
                  setSlide({
                    rowData,
                    open: false,
                    data: null,
                    rateData: null,
                  });
                },
                onClickAllRates: (rowData) => {
                  dispatch(selectProduct(rowData));
                  dispatch(setAllRatesRowData(rowData));
                },
              }}
              onRowClick={(rowData) => {
                handleSelectRow(rowData);
              }}
              noResultsPlaceholder={
                <RowsGridEmpty loanPrograms={isLoanPrograms} rows={rows} />
              }
            />
          </StyledDataTableWrapper>
        </div>
      </>
    );
  }
  return null;
};

EligibleProductsGrid.defaultProps = {
  rows: [],
  activeFilters: [],
  onFilterChange: () => null,
  isLoanPrograms: false,
  refreshPrograms: false,
  onSearch: () => null,
  updateAdjustmentDetailsCache: () => null,
};

EligibleProductsGrid.propTypes = {
  rows: arrayOf(shape({})),
  activeFilters: arrayOf(shape({})),
  isLoanPrograms: arrayOf(shape({})),
  onFilterChange: func,
  refreshPrograms: bool,
  onSearch: func,
  updateAdjustmentDetailsCache: func,
};
