import { jsx } from "react/jsx-runtime";
import { DSFormLayoutBlockItem } from "@elliemae/ds-form-layout-blocks";
import { DSMultiComboBox } from "@elliemae/ds-form-multi-combobox";
import { DSSingleComboBox } from "@elliemae/ds-form-single-combobox";
import { Grid } from "@elliemae/ds-grid";
import { useMemo } from "react";
import { useFormState } from "react-hook-form";
import _ from "lodash";
import {
  useMultiComboboxFieldUpdateLogic,
  useSingleComboboxFieldUpdateLogic
} from "./utils/fieldUpdateLogic";
import { useFieldVisibilityAndDisability } from "./utils/fieldVisibilityAndDisability";
const Combobox = ({
  renderedField,
  schemaForm,
  rules,
  id,
  name,
  label,
  required,
  isMulti,
  pathValue,
  feedbackMessage,
  ui,
  options,
  visible,
  disabled,
  disableCheck,
  visibleCheck,
  preview
}) => {
  const singleComboboxFieldUpdateLogic = useSingleComboboxFieldUpdateLogic();
  const multiComboboxFieldUpdateLogic = useMultiComboboxFieldUpdateLogic();
  const { isVisible, isDisabled } = useFieldVisibilityAndDisability({
    schemaForm,
    rules,
    visible,
    disabled,
    visibleCheck,
    disableCheck,
    preview
  });
  const { errors } = useFormState({ control: schemaForm.control });
  const errorMessage = _.get(errors, pathValue)?.message;
  const allComboboxOptions = useMemo(() => {
    const newOpts = options.filter((option) => option.visible).map((option) => ({
      dsId: `${option.id}`,
      type: "option",
      label: `${option.text}`,
      value: `${option.value}`
    }));
    return newOpts || [];
  }, [options]);
  return /* @__PURE__ */ jsx(Grid, { span: ui.col_size, style: { display: isVisible ? "block" : "none" }, children: /* @__PURE__ */ jsx(
    DSFormLayoutBlockItem,
    {
      name,
      label,
      inputID: name,
      required,
      feedbackMessage,
      hasError: !!errors[id] || !!errorMessage,
      validationMessage: errors[id]?.message?.toString() || errorMessage,
      children: isMulti ? /* @__PURE__ */ jsx(
        DSMultiComboBox,
        {
          ...renderedField,
          allOptions: allComboboxOptions,
          selectedValues: Array.isArray(renderedField?.value) ? renderedField.value.map(
            (val) => allComboboxOptions.find(
              (option) => option.value === val.toString()
            )
          ).filter(Boolean) : [],
          onChange: multiComboboxFieldUpdateLogic(
            renderedField,
            schemaForm,
            id
          ),
          id: pathValue,
          disabled: isDisabled
        }
      ) : /* @__PURE__ */ jsx(
        DSSingleComboBox,
        {
          ...renderedField,
          allOptions: allComboboxOptions,
          onChange: singleComboboxFieldUpdateLogic(
            renderedField,
            schemaForm,
            id
          ),
          selectedValue: allComboboxOptions.find(
            (option) => option.value === renderedField?.value?.toString()
          ) || null,
          id: pathValue,
          disabled: isDisabled
        }
      )
    }
  ) });
};
export {
  Combobox
};
