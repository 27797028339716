import * as Yup from "yup";
import _ from "lodash";
import { isSchema } from "yup";
const evaluateCondition = (condition, data, rules) => {
  const op = condition.op;
  const params = condition.params;
  switch (op) {
    case "and":
      return params.every((p) => evaluateCondition(p, data, rules));
    case "or":
      return params.some((p) => evaluateCondition(p, data, rules));
    case "isFieldEqualToValue":
      return data?.[params[0]] === params[1];
    case "isFieldNotEqualToValue":
      return data?.[params[0]] !== params[1];
    case "isFieldMoreThanValue":
      return parseFloat(data?.[params[0]]) > parseFloat(params[1]);
    case "isFieldLessThanValue":
      return parseFloat(data?.[params[0]]) < parseFloat(params[1]);
    case "isFieldMoreThanOrEqualToValue":
      return parseFloat(data?.[params[0]]) >= parseFloat(params[1]);
    case "isFieldLessThanOrEqualToValue":
      return parseFloat(data?.[params[0]]) <= parseFloat(params[1]);
    case "isEmptyField":
      return !data?.[params[0]];
    case "isNotEmptyField":
      return !!data?.[params[0]];
    default:
      const rule = rules.find((r) => r.id === op);
      return rule?.instructions.some(
        (inst) => evaluateCondition(inst.condition, data, rules)
      ) ?? false;
  }
};
const getFieldsUsedByHandler = (ruleId, rules) => {
  const visited = /* @__PURE__ */ new Set();
  const walk = (ruleId2) => {
    const rule = rules.find((r) => r.id === ruleId2);
    if (!rule) return;
    for (const instr of rule.instructions) {
      collectFields(instr.condition);
    }
  };
  const collectFields = (condition) => {
    if (!condition) return;
    if (condition.op === "and" || condition.op === "or") {
      condition.params.forEach(collectFields);
    } else if (condition.params?.[0] && typeof condition.params[0] === "string") {
      visited.add(condition.params[0]);
    } else if (typeof condition.op === "string") {
      walk(condition.op);
    }
  };
  walk(ruleId);
  return Array.from(visited);
};
const createNestedSchema = (flatFields) => {
  const nestedShape = {};
  for (const path in flatFields) {
    _.set(nestedShape, path, flatFields[path]);
  }
  ["LR", "borrowers", "otherRealEstate"].forEach((key) => {
    delete nestedShape[key];
  });
  const buildYupShape = (obj) => {
    if (isSchema(obj)) return obj;
    if (typeof obj !== "object" || obj === null)
      return Yup.mixed().notRequired();
    const keys = Object.keys(obj);
    if (keys.length === 0) return Yup.mixed().notRequired();
    const shape = {};
    for (const key of keys) {
      shape[key] = buildYupShape(obj[key]);
    }
    return Yup.object().shape(shape);
  };
  return buildYupShape(nestedShape);
};
const generateYupSchema = (customSchema) => {
  const { schema, schemaRules } = customSchema;
  const flatFields = {};
  const excludedRoots = [
    "LR",
    "borrowers",
    "otherRealEstate",
    "lockRequestAdditionalFields"
  ];
  const traverseFields = (items) => {
    for (const item of items) {
      if (item.type === "field_group") {
        traverseFields(item.fields);
      } else {
        const path = item.modelPath || item.encompass_ids?.[0]?.modelPath;
        if (path) {
          const label = item.label || path;
          const isRequired = item.required;
          const visibleHandler = item.visible_check?.handler;
          const deps = visibleHandler ? getFieldsUsedByHandler(visibleHandler, schemaRules) : [];
          let yupField = Yup.mixed().nullable();
          if (isRequired) {
            if (visibleHandler) {
              yupField = yupField.when(deps, {
                is: (...vals) => {
                  const data = Object.fromEntries(
                    deps.map((k, i) => [k, vals[i]])
                  );
                  const isActuallyVisible = item.visible && !evaluateCondition(
                    { op: visibleHandler },
                    data,
                    schemaRules
                  );
                  return isActuallyVisible;
                },
                then: (schema2) => schema2.required(`${label} is required`).test("required-any", `${label} is required`, (value) => {
                  return value !== void 0 && value !== null && value !== "";
                }),
                otherwise: (schema2) => schema2.notRequired()
              });
            } else {
              yupField = yupField.required(`${label} is required`).test("required-any", `${label} is required`, (value) => {
                return value !== void 0 && value !== null && value !== "";
              });
            }
          }
          const topLevelKey = path.split(".")[0];
          if (!excludedRoots.includes(topLevelKey)) {
            flatFields[path] = yupField.label(label);
          }
        }
      }
    }
  };
  for (const screen of schema.screens) {
    if (screen.type === "screen_group") {
      screen.screens?.forEach((subScreen) => {
        const screenDef = schema.screens.find(
          (s) => s.id === subScreen.id
        );
        screenDef && traverseFields(screenDef.fields);
      });
    } else {
      traverseFields(screen.fields);
    }
  }
  return createNestedSchema(flatFields);
};
export {
  generateYupSchema
};
