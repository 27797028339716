import { useSelector, shallowEqual } from "react-redux";
import { get, pick } from "lodash";
import { randomUUID } from "@frontend/utils";
const generateObjectDependencies = (object, nameOrPath) => {
  let valuesSelector = {};
  nameOrPath.map(
    ({ path, values }) => Array.isArray(values) && values.forEach((value) => {
      valuesSelector = {
        ...valuesSelector,
        [value]: path ? object[path]?.[value] : object[value] ?? null
      };
    })
  );
  return valuesSelector;
};
const loanDataFieldSelector = (nameOrPath, defaultValue = null) => ({ EPPS: { loanInfo = {} } }) => {
  if (typeof nameOrPath === "string")
    return { value: get(loanInfo, nameOrPath) ?? defaultValue };
  if (Array.isArray(nameOrPath))
    return generateObjectDependencies(loanInfo, nameOrPath);
  return nameOrPath ? null : loanInfo;
};
const isShallowEqual = (obj1, obj2) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};
const useLoanDataFieldSelectorHook = (pathValue, defaultValue) => useSelector(loanDataFieldSelector(pathValue, defaultValue), isShallowEqual);
const selectLoanDataField = (fieldName, defaultValue = null) => ({ EPPS: { loanInfo = {} } }) => {
  return get(loanInfo, fieldName, defaultValue);
};
const useLoanDataFieldSelector = (field, defaultValue = null) => useSelector(selectLoanDataField(field, defaultValue), shallowEqual);
const getDefaultFocusedDate = () => {
  const parsedCurrentDateValue = (/* @__PURE__ */ new Date()).toLocaleDateString("en-US", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric"
  });
  return parsedCurrentDateValue;
};
const formatTime = (date) => {
  const parsedDate = new Date(date);
  if (date) {
    let hours = parsedDate.getHours();
    let minutes = parsedDate.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours %= 12;
    if (hours === 0) {
      hours = 12;
    }
    if (hours < 10) {
      hours = `0${hours}`;
    }
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    return `${hours}:${minutes} ${ampm}`;
  }
  return "";
};
const formatDate = (date) => {
  if (date) {
    const parsedDate = new Date(date);
    const year = parsedDate.getFullYear();
    const month = (1 + parsedDate.getMonth()).toString().padStart(2, "0");
    const day = parsedDate.getDate().toString().padStart(2, "0");
    return `${month}/${day}/${year}`;
  }
  return "";
};
const formatDateTime = (date) => {
  const formatedDate = formatDate(date);
  const formatedTime = formatTime(date);
  if (formatedDate !== "" && formatedTime !== "")
    return `${formatedDate} ${formatedTime}`;
  return "";
};
const selectLookups = (nameOrPath, defaultValue = null) => (state) => {
  const data = state?.eppsData?.lookups?.data || {};
  if (Array.isArray(nameOrPath)) {
    return pick(data, nameOrPath);
  }
  if (typeof nameOrPath === "string") {
    return get(data, nameOrPath, defaultValue);
  }
  return data;
};
const useLookup = (lookup, defaultValue) => useSelector(selectLookups(lookup, defaultValue), shallowEqual);
const handleTotalAmountValue = (schemaForm, value) => {
  const amounts = schemaForm.getValues([
    "loanInformation.firstMortgageAmount",
    "loanInformation.financedAmount",
    "loanInformation.feeAmountFinanced"
  ]);
  const totalValue = calculateTotalLoanAmount(
    amounts[0],
    amounts[1],
    amounts[2]
  );
  return totalValue;
};
const parseCompensationValue = (value) => {
  let getValue = null;
  if (value) {
    if (value === "Borrower-Paid") {
      getValue = 1;
    } else {
      getValue = 2;
    }
  }
  return getValue;
};
const safeSum = (...numbers) => {
  let res = 0;
  numbers.forEach((num) => {
    res += Number(num || 0);
  });
  return res;
};
const inRange = (a, b, c) => !Number.isNaN(parseFloat(a)) && a >= b && a <= c;
const calculateTotalLoanAmount = (firstMortgageAmount, financedAmount, govtFeeFinancedAmount) => {
  let result = 0;
  const ParsedLoanAmount = parseInt(firstMortgageAmount, 10) || 0;
  const ParsedFinancedAmount = parseInt(financedAmount, 10) || 0;
  const ParsedGovtFeeFinancedAmount = parseFloat(govtFeeFinancedAmount) || 0;
  result = safeSum(
    ParsedLoanAmount,
    Math.floor(ParsedFinancedAmount),
    ParsedGovtFeeFinancedAmount
  );
  return result;
};
const evaluateRule = (rule, loanInfo, ruleSet, preview) => {
  const getValue = (obj, path) => {
    return path.split(".").reduce((o, key) => o ? o[key] : void 0, obj);
  };
  function evaluateCondition(condition) {
    const { op, params } = condition;
    const referencedRule = ruleSet.find((r) => r.id === op);
    if (referencedRule) {
      return evaluateRule(referencedRule, loanInfo, ruleSet, preview);
    }
    if (preview) {
      if (params[0] === "nonLoanValues.lockStatus") {
        return true;
      }
    }
    let currentValue = getValue(loanInfo, params[0]);
    if (typeof currentValue === "boolean" || typeof currentValue === "number") {
      currentValue = String(currentValue);
    }
    let targetValue = params[1];
    if (typeof targetValue === "boolean" || typeof targetValue === "number") {
      targetValue = String(targetValue);
    }
    switch (op) {
      case "isEmptyField":
        return !currentValue;
      case "isNotEmptyField":
        return Boolean(currentValue);
      case "isFieldEqualToValue":
        const value = currentValue;
        return Array.isArray(value) ? value.map(String).includes(String(params[1])) : String(value) === String(params[1]);
      case "isFieldNotEqualToValue":
        return currentValue !== String(params[1]);
      case "isFieldMoreThanValue":
        return currentValue > targetValue;
      case "isFieldLessThanValue":
        return currentValue < targetValue;
      case "isFieldMoreThanOrEqualToValue":
        return currentValue >= targetValue;
      case "isFieldLessThanOrEqualToValue":
        return currentValue <= targetValue;
      default:
        throw new Error(`Unknown operation: ${op}`);
    }
  }
  const evaluateLogicalCondition = (condition) => {
    if (!condition || !condition.op || !condition.params) return false;
    const results = condition.params.map(evaluateCondition);
    return condition.op === "and" ? results.every(Boolean) : results.some(Boolean);
  };
  return rule.instructions.every(
    (instruction) => evaluateLogicalCondition(instruction.condition)
  );
};
const evaluateRuleById = (ruleId, loanInfo, ruleSet, preview) => {
  const rule = ruleSet.find((r) => r.id === ruleId);
  if (!rule) return true;
  return evaluateRule(rule, loanInfo, ruleSet, preview);
};
const useUserData = () => useSelector(({ EPPS: { userData = {} } }) => {
  return userData;
});
const lookupsSelector = (lookupName) => ({ eppsData: { lookups: { data = {} } = {} } }) => {
  if (lookupName) {
    const getName = String(lookupName).replace(/[0-9]/g, "").replace("-", "");
    return get(data, getName);
  }
  return lookupName ? [] : data;
};
const useLookupOptions = (lookupName) => useSelector(lookupsSelector(lookupName));
const optionBody = ({ id, lookup, ...rest }) => ({
  dsId: `${lookup}-${id || "none"}`,
  type: "option",
  ...rest
});
const transformOfficeUsers = (officeUsers) => {
  const result = [];
  let currentOffice = null;
  officeUsers.forEach((obj) => {
    if (obj.office !== currentOffice) {
      result.push({
        dsId: `officeUsers-${randomUUID() || "none"}`,
        type: "section",
        label: obj.office
      });
      currentOffice = obj.office;
    }
    result.push(obj);
  });
  return result;
};
const generateOfficeUsersComboboxOptionsWithItemLayout = (lookup = "", options = []) => {
  const comboboxOptions = Array.isArray(options) && options.map((item) => {
    const extraProps = {};
    const label = `${item.firstName} ${item.lastName}`;
    const value = item.userId;
    const { login } = item;
    const { office } = item;
    const officerFormat = { label, value, login, office, id: randomUUID() };
    return optionBody({ lookup, ...extraProps, ...officerFormat });
  });
  return transformOfficeUsers(comboboxOptions) || [];
};
const zeroPadCensusTract = (num = "") => {
  if (num && !Number.isNaN(num)) {
    const places = 7;
    const parseNum = Number(num).toFixed(2);
    return parseNum.padStart(places, "0");
  }
  return "";
};
export {
  calculateTotalLoanAmount,
  evaluateRuleById,
  formatDate,
  formatDateTime,
  formatTime,
  generateObjectDependencies,
  generateOfficeUsersComboboxOptionsWithItemLayout,
  getDefaultFocusedDate,
  handleTotalAmountValue,
  inRange,
  isShallowEqual,
  loanDataFieldSelector,
  optionBody,
  parseCompensationValue,
  safeSum,
  selectLoanDataField,
  selectLookups,
  transformOfficeUsers,
  useLoanDataFieldSelector,
  useLoanDataFieldSelectorHook,
  useLookup,
  useLookupOptions,
  useUserData,
  zeroPadCensusTract
};
